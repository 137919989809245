import { ObjectMaybe, isNotEmptyArray, stringifyQueryParams } from '@bit/yellow_class.utils.common-helpers';
import Breadcrumbs from 'components/Breadcrumbs';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { SelectField } from 'reusableComponents/Form/Select';
import Pagination from 'reusableComponents/Pagination/Pagination';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import RankModifier from 'reusableComponents/rankModifier';
import { actionSpreader, parseQueryParams } from 'utils/commonHelpers';
import history from '../../App/history';
import { setApiParams, setPage } from '../ExercisesSlice';
import { fetchExercises, updateExerciseRank } from '../actions/exercises.actions';
import { changeExerciseStatus } from '../utils/changeExerciseStatus';
import { statusList } from '../utils/helpers';
import { ContentDocumentStatusEnum, TExercise } from '../utils/types';
import AddExistingQuestionPopup from './AddExistingQuestionPopup';

const heads = [
	{
		accessor: 'title',
		Header: 'Title',
	},
	{
		accessor: 'aliasTitle',
		Header: 'Alias Title',
	},
	{
		accessor: 'type',
		Header: 'type',
	},
	{
		accessor: 'adminCode',
		Header: 'adminCode',
	},
	{
		accessor: 'playUiType',
		Header: 'Play Type',
	},
	{
		accessor: 'rank',
		Header: 'Rank',
	},
	{
		accessor: 'status',
		Header: 'Status',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({
	id: exerciseId,
	status,
	showAddQuestionPopup,
	playUiType,
	questions,
	video,
}: Pick<TExercise, 'playUiType' | 'questions' | 'video' | 'id' | 'status'> & { showAddQuestionPopup: () => void }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const dispatch = useDispatch();
	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					onClick={() => {
						history.push(`/exercise/questions/list${stringifyQueryParams({ exerciseId })}`);
					}}
					disabled={false}
				>
					Show Questions
				</DropdownItem>
				{[ContentDocumentStatusEnum.INACTIVE.toString(), ContentDocumentStatusEnum.DRAFT.toString()].includes(status) && (
					<DropdownItem
						onClick={() => {
							changeExerciseStatus({ exerciseId, status: ContentDocumentStatusEnum.ACTIVE, playUiType, questions, video, dispatch });
						}}
						disabled={false}
					>
						Set ACTIVE
					</DropdownItem>
				)}
				{status === ContentDocumentStatusEnum.ACTIVE.toString() && (
					<DropdownItem
						onClick={() => {
							changeExerciseStatus({ exerciseId, status: ContentDocumentStatusEnum.INACTIVE, playUiType, questions, video, dispatch });
						}}
						disabled={false}
					>
						Set INACTIVE
					</DropdownItem>
				)}
				<DropdownItem
					onClick={() => window.open(`/exercise/add-edit${stringifyQueryParams({ exerciseId, mode: 'edit' })}`, '_blank')}
					disabled={false}
				>
					Edit Exercise
				</DropdownItem>
				<DropdownItem onClick={showAddQuestionPopup}>Add an existing Question</DropdownItem>
				<DropdownItem
					onClick={() => window.open(`/exercise/questions/add-edit${stringifyQueryParams({ exerciseId, mode: 'add' })}`, '_blank')}
					disabled={false}
				>
					Add a new Question
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({
	exercises,
	showAddQuestionPopup,
	handleExerciseRankChange,
}: {
	exercises: TExercise[];
	showAddQuestionPopup: (exerciseId: string) => void;
	handleExerciseRankChange: (uniqueId: string, rank: number, additionalParams: Record<string, any>) => any;
}) => {
	return exercises.map((exercise) => {
		const { id, title, aliasTitle, type, adminCode, playUiType, status, rank, video, rankNumber, rankDecimal } = exercise;
		const actualRank = `${rankNumber}.${rankDecimal}`;
		return {
			id,
			title,
			aliasTitle,
			type,
			adminCode,
			playUiType,
			status,
			actions: (
				<Actions
					id={id}
					status={status}
					playUiType={playUiType}
					questions={exercise.questions}
					showAddQuestionPopup={() => showAddQuestionPopup(id)}
					video={video}
				/>
			),
			rank: <RankModifier onSubmit={handleExerciseRankChange} uniqueId={id} rank={actualRank} additionalParams={{}} />,
		};
	});
};

const ExerciseList = (props) => {
	const dispatch = useDispatch();

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const exercisesState = useSelector(({ exercises }) => {
		return {
			exercises: exercises.exercises,
			additionalData: exercises.additionalData,
			isLoading: exercises.isLoading,
			isSubmitting: exercises.isSubmitting,
			apiParams: exercises.apiParams,
			total: exercises.total,
			page: exercises.page,
		};
	});
	const user = useSelector(({ auth }) => auth.user);
	const [exercisesRow, setExercisesRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);
	const addExistingQuestionPopupRef = useRef<any>();
	const [subtopicId, setSubtopicId] = useState(null);

	// exercises to be sorted in ascending order of rank on page load
	React.useEffect(() => {
		const { apiParams } = exercisesState;

		dispatch(setApiParams({ ...apiParams, sortKey: 'rank', sortOrder: '1' }));
	}, []);

	React.useEffect(() => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));
		if (qp.subtopicId) {
			setSubtopicId(qp.subtopicId);
		}

		dispatch(
			fetchExercises({
				...exercisesState.apiParams,
				...(qp.curriculumId && { curriculumId: qp.curriculumId }),
				...(qp.subtopicId && { subtopicId: qp.subtopicId }),
				...(qp.type && { exerciseType: qp.type }),
				filters: {
					...(qp.hanging && { adminCodeRegex: '_hang_' }),
				},
			})
		);
	}, [exercisesState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(exercisesState.page);
	}, [exercisesState.page]);

	const showAddQuestionPopup = (exerciseId: string) => {
		addExistingQuestionPopupRef?.current?.show?.(exerciseId);
	};
	const handleExerciseRankChange = async (exerciseId, rank, additionalParams) => {
		const qp = ObjectMaybe(parseQueryParams(props.location.search));

		dispatch(updateExerciseRank({ exerciseId, rank, subtopicId: qp.subtopicId, updatedBy: user.id }));
	};
	React.useEffect(() => {
		if (isNotEmptyArray(exercisesState.exercises)) {
			const qp = ObjectMaybe(parseQueryParams(props.location.search));
			if (qp.type === 'DEMO') {
				dispatch(
					actionSpreader(setBreadcrumbs.type, {
						breadcrumbs: [
							{
								text: 'Curriculum',
								url: '/exercise/curriculum/list',
							},
							{
								text: 'Exercise',
								url: `/exercise/list${stringifyQueryParams({ type: qp.type })}`,
							},
						],
					})
				);
			} else {
				dispatch(
					actionSpreader(setBreadcrumbs.type, {
						breadcrumbs: [
							{
								text: 'Curriculum',
								url: '/exercise/curriculum/list',
							},
							{
								text: 'Module',
								url: `/exercise/module/list${stringifyQueryParams({ curriculumId: exercisesState.additionalData?.curriculum?.id })}`,
							},
							{
								text: 'Subtopic',
								url: `/exercise/subtopic/list${stringifyQueryParams({ moduleEnum: exercisesState.additionalData?.module?.moduleEnum })}`,
							},
							{
								text: 'Exercise',
								url: `/exercise/list${stringifyQueryParams({ subtopicId: qp.subtopicId })}`,
							},
						],
					})
				);
			}
			const structuredRows = createNewRows({
				exercises: exercisesState.exercises,
				showAddQuestionPopup,
				handleExerciseRankChange,
			});
			setExercisesRow(structuredRows);
		} else {
			setExercisesRow([]);
		}
	}, [exercisesState.exercises]);

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = exercisesState;
		const skip = apiParams.limit * (itemsPage - 1);
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					skip,
				})
			);
		}
	};
	const onFilterChange = (key, val) => {
		dispatch(
			setApiParams({
				...exercisesState.apiParams,
				filters: { ...exercisesState.apiParams.filters, [key]: val },
			})
		);
	};
	const onSort = (sortColumn, sortDirection) => {
		if (sortColumn !== 'actions' && sortDirection !== 'NONE') {
			const sortOrder = sortDirection === 'ASC' ? '1' : '-1';

			dispatch(setApiParams({ ...exercisesState.apiParams, sortKey: sortColumn, sortOrder }));
		}
	};
	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={6}>
						<h3>Exercises Listing</h3>
					</Col>
					<Col sm={3}>
						<SelectField
							clearable
							placeholder="Status"
							name="status"
							options={statusList}
							onChange={(val) => onFilterChange('status', val)}
						/>
					</Col>
					{subtopicId && (
						<Col sm={3}>
							<Button onClick={() => window.open(`/exercise/add-edit${stringifyQueryParams({ subtopicId, mode: 'add' })}`, '_blank')}>
								{' '}
								Add Exercise{' '}
							</Button>
						</Col>
					)}
				</Row>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={4}>
						{exercisesState.additionalData?.curriculum?.displayName && (
							<h4>Curriculum Name : {exercisesState.additionalData?.curriculum?.displayName}</h4>
						)}
						{exercisesState.additionalData?.curriculum?.adminCode && (
							<h4>Curriculum AdminCode : {exercisesState.additionalData?.curriculum?.adminCode}</h4>
						)}
					</Col>
					<Col sm={4}>
						{exercisesState.additionalData?.module?.displayName && (
							<h4>Module Name : {exercisesState.additionalData?.module?.displayName}</h4>
						)}
						{exercisesState.additionalData?.module?.adminCode && (
							<h4>Module AdminCode : {exercisesState.additionalData?.module?.adminCode}</h4>
						)}
					</Col>
					<Col sm={4}>
						{exercisesState.additionalData?.subtopic?.displayName && (
							<h4>Subtopic Name : {exercisesState.additionalData?.subtopic?.displayName}</h4>
						)}
						{exercisesState.additionalData?.subtopic?.adminCode && (
							<h4>Subtopic AdminCode : {exercisesState.additionalData?.subtopic?.adminCode}</h4>
						)}
					</Col>
				</Row>

				{exercisesState.isLoading && <h4> Fetching data...</h4>}

				{isNotEmptyArray(exercisesRow) && (
					<>
						<DataPaginationTable heads={heads} rows={exercisesRow} onSort={onSort} />
						<Pagination
							itemsCount={exercisesState.total}
							itemsToShow={exercisesState.apiParams.limit}
							pageOfItems={pageOfItems}
							onChangePage={onChangePage}
						/>
					</>
				)}
				{exercisesState.total === 0 && <div className="">No data</div>}
				<AddExistingQuestionPopup ref={addExistingQuestionPopupRef} />
			</CardBody>
		</Card>
	);
};

export default ExerciseList;
